.quill_container {
  overflow: auto;
}

.quill_container .ql-container {
  padding: 0px !important;
  margin: 0px !important;
  color: black !important;
}

.quill_container .ql-container {
  color: black !important;
  background-color: transparent;
  overflow: hidden;
  border: none !important;
  min-height: 0px;
  height: auto;
}
