

.ticket_viewer{
  width: 100%;
  flex: 1;
  display: flex;

}
.table_row td {
  padding-top: 8px;
  padding-bottom: 8px;
}

.table_row.content:hover {
  background-color: #f5f5f5;
}
.table_row.content.dark:hover {
  background-color: #696969;
}

.grid_container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: 16px;
  padding: 2px;
 flex: 1;
}

.grid_body{
  flex: 1;
  width: 100%;
  overflow: scroll;
}

.grid_body::-webkit-scrollbar {
  width: none;
  background: "transparent"; 
   display: none;
}

.grid_body.stop_scroll{
  overflow: hidden;
}

@media screen and (max-width: 450px) {
  .grid_container {
    grid-template-columns: repeat(auto-fill, 100%);
  }
}
